import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../../../global/messages/modal/modal.components';
import Selector from '../../../form/select/selector';
import Input from '../../../form/input/input';
import { toast } from 'react-toastify';
import { MY_GROUPS, USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import { changeGroupName, getTeacherGroups } from '../../../service/api';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { UserType } from '../../users/userManagementPage';
import { SelectOptionType } from "../../../service/types";

const UpdateGroupsModal = ({ user, toggle }: ModalToggleProps & { user: UserType }) => {
  const queryClient = useQueryClient();

  const groupsDataQuery = useQuery(
    [MY_GROUPS, user?.id],
    () => getTeacherGroups(user.id),
    {
      staleTime: Infinity,
      select: (data) => data?.data.data || [],
      onError: () => {
        toast.error('Failed to fetch user groups. Please try again.');
      },
    }
  );

  const groups = Array.isArray(groupsDataQuery.data) ? groupsDataQuery.data : [];

  const UpdateGroupsModalSchema = Yup.object().shape({
    group: Yup.object()
      .shape({
        label: Yup.string().required('Please select a team.'),
        value: Yup.string().required('Team ID is required.'),
      })
      .nullable()
      .required('Team selection is required.'),
    name: Yup.string().required('New team name is required.').max(64, 'Name must not exceed 64 characters.'),
  });

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(UpdateGroupsModalSchema),
    defaultValues: { group: null, name: '' },
  });

  const UpdateGroupsModalMutation = useMutation(changeGroupName, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      queryClient.invalidateQueries([MY_GROUPS]);

      toast.success('Team name updated successfully.');
      toggle(false);
    },
    onError: () => {
      toast.error('You already have a team with this name.');
    },
  });

  const groupOptions = groups.map((group) => ({
    label: group.name,
    value: group.id,
  }));

  const handleFormSubmit = (formValues: any) => {
    const { group, name } = formValues;

    if (group?.value) {
      UpdateGroupsModalMutation.mutate({
        user_id: user.id,
        group_id: group.value,
        group_name: name,
      });
    } else {
      toast.error('Please select a valid team.');
    }
  };

  return (
    <Modal open toggle={() => toggle(false)} preventCloseOnClickOnMask>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Body>
          <p>Select a team and provide a new name for it.
          </p>
          <div className="mb-4"></div>
          <div className="field-mb">
            <Controller
              name="group"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Selector
                  {...field}
                  label="Team"
                  error={errors.group?.label?.message}
                  options={groupOptions}
                  onChange={(option: SelectOptionType<string>) => onChange(option || null)}
                  value={value}
                  isLoading={groupsDataQuery.isFetching || groupsDataQuery.isLoading}
                  disabled={groupsDataQuery.isFetching || groupsDataQuery.isLoading}
                />
              )}
            />
          </div>
          <div className="mb-4"></div>
          <div className="field-mb">
            <Input
              {...register('name')}
              required
              id="name"
              label="Team name"
              placeholder="Please enter a name for this team"
              error={errors.name?.message}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)}/>
          <Modal.ConfirmButton
            type="submit"
            disabled={!isValid || isSubmitting || UpdateGroupsModalMutation.isLoading}
            label={UpdateGroupsModalMutation.isLoading ? "Updating..." : "Update Team"}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UpdateGroupsModal;

