import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Modal from '../../../global/messages/modal/modal.components';
import { MENTORS, USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import * as Yup from 'yup';
import { changeInductionLead } from "../../../service/api";
import Selector from "../../../form/select/selector";
import { SelectOptionType } from "../../../service/types";
import { ReviewUserTable } from "../components";
import { toast } from "react-toastify";
import { getSchoolMentors } from "../../../service/api/adminApi";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

const ChangeInductionLeadModal = ({ user, toggle }: ModalToggleProps & { user: UserType }) => {
  const queryClient = useQueryClient();

  // Form setup
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        mentors: Yup.object()
          .shape({
            label: Yup.string().required("Mentor name is required."),
            value: Yup.string().required("Mentor ID is required."),
          })
          .nullable()
          .required("You must select a mentor."),
      })
    ),
    defaultValues: {
      mentors: null as SelectOptionType<string> | null,
    },
  });

  // Mutation for changing Induction Lead
  const changeInductionLeadMutation = useMutation(changeInductionLead, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      queryClient.invalidateQueries([MENTORS]);

      toggle(false);
      toast.success('Privileges successfully updated.');
    },
    onError: () => {
      toast.error('Failed to update privileges. Please try again.');
    },
  });

  // Fetching school mentors
  const schoolMentorsQuery = useQuery([MENTORS], () => getSchoolMentors(user.id), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    onError: () => {
      toast.error("Failed to fetch mentors. Please reload the page.");
    },
  });

  return (
    <Modal preventCloseOnClickOnMask>
      <form
        onSubmit={handleSubmit((values) =>
          changeInductionLeadMutation.mutate({
            currentIL_id: user.id,
            newIL_id: values.mentors?.value,
          })
        )}
      >
        <Modal.Body>
          <p className="sm-field-mb">
            You are about to remove the Induction Lead privileges from this user account.
          </p>
          <div className="sm-field-mb">
            <ReviewUserTable user={user}/>
          </div>
          <p className="sm-field-mb">
            Please select another user from this school to transfer Induction Lead privileges to.
          </p>
          <div className="field-mb">
            <Controller
              control={control}
              name="mentors"
              render={({ field: { onChange, value, ...field } }) => (
                <Selector
                  {...field}
                  id="newIL_id"
                  label="Select new induction lead"
                  error={errors.mentors?.message}
                  options={
                    (schoolMentorsQuery.data ?? []).map((mentor) => ({
                      label: mentor.fullName,
                      value: mentor.id,
                    })) ?? []
                  }
                  onChange={(option: SelectOptionType<string>) => onChange(option || null)} // Pass the selected object or null
                  value={value}
                  isLoading={!schoolMentorsQuery.isFetched || schoolMentorsQuery.isFetching}
                  disabled={!schoolMentorsQuery.isFetched || schoolMentorsQuery.isFetching}
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)}/>
          <Modal.ConfirmButton
            type="submit"
            disabled={!isValid || isSubmitting || changeInductionLeadMutation.isLoading}
            label={changeInductionLeadMutation.isLoading ? "Processing..." : "Confirm"}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangeInductionLeadModal;
