import React from 'react';
import { Formik } from 'formik';
import { UserType } from '../userManagementPage';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { ReviewUserTable } from '../components';
import { useMutation, useQueryClient } from 'react-query';
import { changeUserType } from '../../../service/api';
import Modal from '../../../global/messages/modal/modal.components';
import { USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import * as Yup from "yup";
import Selector from "../../../form/select/selector";
import { NIOT_TAGS, TAG_MENTOR, TAG_ECT } from "../../../resources/roles";
import { toast } from "react-toastify";

const typeList = NIOT_TAGS.filter(tag =>
  (tag.value === TAG_MENTOR) ||
  (tag.value === TAG_ECT)
).map(tag => ({
  label: tag.label,
  value: tag.value,
}));

const UpdateUserRoleTypeModal = ({ user, toggle }: ModalToggleProps & { user: UserType }) => {
  const queryClient = useQueryClient();

  const updateUserTypeMutation = useMutation(changeUserType, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toggle(false);
      toast.success('Role successfully updated.');
    },
    onError: () => {
      toast.error('Failed to update role. Please try again.');
    },
  });

  const userType = user?.is_early_career_teacher
    ? 'ECT'
    : user?.mentor
      ? 'MENTOR'
      : '';

  return (
    <Formik
      initialValues={{ role: userType }}
      validationSchema={Yup.object().shape({
        role: Yup.string().required('Please select a role'),
      })}
      validateOnMount
      onSubmit={({ role }) => {
        updateUserTypeMutation.mutate({ user_id: user.id, user_type: role });
      }}
    >
      {({ isValid, handleSubmit, values, setFieldValue }) => (
        <Modal>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <p className="sm-field-mb">
                You are about to change the role type for this user account.
              </p>
              <div className="sm-field-mb">
                <ReviewUserTable user={user}/>
              </div>
              <Selector
                id="roles"
                label="Role"
                placeholder="Please select a role"
                required
                value={typeList.find((role) => role.value === values.role)}
                onChange={(option: any) => setFieldValue('role', option.value)}
                options={typeList}
                isClearable={false}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)}/>
              <Modal.ConfirmButton
                type="submit"
                disabled={!isValid || updateUserTypeMutation.isLoading}
                label={updateUserTypeMutation.isLoading ? 'Loading...' : 'Confirm'}
              />
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default UpdateUserRoleTypeModal;
