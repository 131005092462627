import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { changeSchool, getSchools } from '../../../service/api';
import Modal from '../../../global/messages/modal/modal.components';
import { ALL_SCHOOLS, USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import * as Yup from 'yup';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Selector from "../../../form/select/selector";
import { toast } from 'react-toastify';
import { ReviewUserTable } from "../components";

const ChangeSchoolModal = ({ user, toggle }) => {
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState('');

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        schools: Yup.object()
          .shape({
            label: Yup.string().required("School name is required."),
            value: Yup.string().required("School ID is required."),
          })
          .nullable()
          .required("You must select a school."),
      })
    ),
    defaultValues: {
      schools: null,
    },
  });

  // Mutation for changing school
  const changeSchoolMutation = useMutation(changeSchool, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toggle(false);
      toast.success('School successfully updated.');
    },
    onError: () => {
      toast.error('Failed to update school. Please try again.');
    },
  });

  // Fetching filtered schools list
  const schoolsListQuery = useQuery([ALL_SCHOOLS, searchTerm], () => getSchools(searchTerm), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    onError: () => {
      toast.error("Failed to fetch schools. Please reload the page.");
    },
  });

  return (
    <Modal preventCloseOnClickOnMask>
      <form
        onSubmit={handleSubmit((values) =>
          changeSchoolMutation.mutate({
            school_id: values.schools.value,
            user_id: user.id,
          })
        )}
      >
        <Modal.Body>
          <p className="sm-field-mb">
            You are about to change the school for this user.
          </p>
          <div className="sm-field-mb">
            <ReviewUserTable user={user}/>
          </div>
          <p className="sm-field-mb">
            The user's current school is {user?.organisation || 'N/A'}.
          </p>
          <div className="field-mb">
            <Controller
              control={control}
              name="schools"
              render={({ field: { onChange, value, ...field } }) => (
                <Selector
                  {...field}
                  id="school"
                  label="School/college"
                  placeholder="Start typing to select school/college"
                  required
                  error={errors.schools?.message}
                  options={
                    (schoolsListQuery.data ?? []).map((school) => ({
                      label: school.name,
                      value: school.id,
                    }))
                  }
                  onInputChange={(inputValue) => setSearchTerm(inputValue)}
                  onChange={(option) => onChange(option || null)}
                  value={value}
                  isLoading={schoolsListQuery.isFetching}
                  disabled={schoolsListQuery.isFetching}
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)}/>
          <Modal.ConfirmButton
            type="submit"
            disabled={!isValid || isSubmitting || changeSchoolMutation.isLoading}
            label={changeSchoolMutation.isLoading ? "Processing..." : "Confirm"}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangeSchoolModal;
