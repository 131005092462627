import React from 'react';
import { Formik } from 'formik';
import { UserType } from '../userManagementPage';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../../global/messages/modal/modal.components';
import { COHORT, USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import * as Yup from 'yup';
import Selector from '../../../form/select/selector';
import { changeCohort } from '../../../service/api';
import { toast } from 'react-toastify';
import { ReviewUserTable } from '../components';

// Define cohort options
const cohortList = [
  { label: 'Cohort 1', value: 1 },
  { label: 'Cohort 2', value: 2 },
];

const ChangeCohortModal = ({ user, toggle }: ModalToggleProps & { user: UserType }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(changeCohort, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      queryClient.invalidateQueries([COHORT]);

      toggle(false);
      toast.success('Cohort successfully updated.');
    },
    onError: () => {
      toast.error('Failed to update cohort. Please try again.');
    },
  });

  return (
    <Formik
      initialValues={{
        cohort: user?.niot_cohort || '',
      }}
      validationSchema={Yup.object().shape({
        cohort: Yup.number()
          .typeError('Cohort must be a number')
          .required('Please select a cohort')
      })}
      validateOnMount
      onSubmit={({ cohort }) => {
        mutate({ cohort, user_id: user.id });
      }}
    >
      {({ isValid, handleSubmit, values, setFieldValue }) => (
        <Modal>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <p className="sm-field-mb">
                You are about to change the cohort for this user.
              </p>
              <div className="sm-field-mb">
                <ReviewUserTable user={user}/>
              </div>
              <p className="sm-field-mb">
                The user belongs to cohort {user?.niot_cohort || 'N/A'}.
              </p>
              <Selector
                id="cohort"
                label="Change cohort"
                placeholder="Please select a cohort"
                required
                value={cohortList.find((c) => c.value === values.cohort)}
                onChange={(option: any) => setFieldValue('cohort', option.value)}
                options={cohortList}
                isClearable={false}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)}/>
              <Modal.ConfirmButton
                type="submit"
                disabled={!isValid || isLoading}
                label={isLoading ? 'Loading...' : 'Confirm'}
              />
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default ChangeCohortModal;
