import { getVariants } from "./api";

export const SCHOOL_VERIFY = 'SCHOOL_VERIFY';
// Registration
export const VALIDATE_TOKEN = 'validate-token';
export const CHECK_SUBSCRIPTION = 'check-subscription';
export const PLAN_TIERS = 'plan-tiers';
// Reset password
export const VALIDATE_RESET_PASSWORD_TOKEN = 'validate-reset-password-token';
// Me
export const ME = 'me';
// Classes
export const SCHOOL_CLASSES = 'schoolClasses';
export const SUBJECTS = 'subjects';
// Feedback
export const FEEDBACK_DEVELOPMENT_PERIODS = 'feedbackDevelopmentPeriods';
export const DIMENSIONS_DATA = 'dimensionsData';
export const ELEMENT_DATA = 'elementData';
export const RADAR_DATA = 'radarData';
export const SUMMARY_DATA = 'summaryData';
export const SCHOOL_DATA = 'SCHOOL_DATA';
export const MAT_SCHOOLS = 'matSchools';
export const FEEDBACK_PERIOD = 'feedbackPeriod';
export const FEEDBACK_SCHOOL_CLASS_OPTIONS = 'FEEDBACK_SCHOOL_CLASS_OPTIONS';
export const ELEMENTS_FEEDBACK = 'elementsFeedback';
export const TIMEFRAME_OPTIONS = 'TIMEFRAME_OPTIONS';
export const COMPLETED_INSTRUMENTS_OPTIONS = 'COMPLETED_INSTRUMENTS_OPTIONS';
export const ALL_PATHWAYS = 'allPathways';
export const DEVELOPMENT_ACTIVITIES = 'developmentActivities';
export const COMPLETED_ENROLMENTS = 'completedEnrolments';
// Groups
export const MY_GROUPS = 'teacherMembersGroups';
export const SCHOOLS_GROUPS = 'schoolsGroups';
export const JOINED_GROUPS = 'joinedGroups';
export const TODAY_MEETINGS = 'todayMeetings';
export const FUTURE_MEETINGS = 'futureMeetings';
export const MEETINGS_AND_EVENTS = 'MEETINGS_AND_EVENTS';
export const CALENDAR_MEETINGS_AND_EVENTS = 'CALENDAR_MEETINGS_AND_EVENTS';
export const MEETINGS_PATHWAYS = 'MEETINGS_PATHWAYS';
export const MEETINGS_TEMPLATES = 'MEETINGS_TEMPLATES';
export const GROUP_MEETINGS = 'GROUP_MEETINGS';
export const MEETING = 'MEETING';
export const GROUP_MEETINGS_ACTIONS_TAGS = 'GROUP_MEETINGS_ACTIONS_TAGS';
export const GROUP_MEMBERS = 'GROUP_MEMBERS';
// Instruments
export const ACTIVE_INSTRUMENTS = 'active-instruments';
export const INSTRUMENT_PERIODS = 'instrument-periods';
export const INSTRUMENT_OPTIONS = 'instrument-options';
// Video observation
export const VIDEO_LIBRARY = 'VIDEO_LIBRARY';
export const VIDEO = 'VIDEO';
export const VIDEO_COMMENTS = 'VIDEO_COMMENTS';
export const VIDEO_TAGS = 'VIDEO_TAGS';
export const VIDEO_SHARE_OPTIONS = 'VIDEO_SHARE_OPTIONS';
export const VIDEO_SHARE_VALUES = 'VIDEO_SHARE_VALUES';
export const VIDEO_TAGSETS = 'VIDEO_TAGSETS';
// Pathway
export const PATHWAYS_V2_LIST = 'PATHWAYS_V2_LIST';
export const DEVELOPMENT_PERIODS = 'developmentPeriods';
export const PATHWAYS_HISTORY = 'pathwaysHistory';
export const PATHWAYS_ELEMENTS = 'PATHWAYS_ELEMENTS';
export const DEVELOPMENT_PERIOD_TEACHER = 'developmentPeriodTeacher';
export const PATHWAYS_PERIOD = 'PATHWAYS_PERIOD';
export const EBE_GOALS = 'ebeGoals';
export const EBE_STRATEGIES = 'ebeStrategies';
export const ADMIN_STRATEGIES = 'adminStrategies';
export const ADMIN_GOALS = 'adminGoals';
export const SHARED_STRATEGIES = 'sharedStrategies';
export const CYCLES = 'cycles';
export const CYCLE_STRATEGIES = 'cycleStrategies';
export const ACTIONS = 'actions';
export const ACTION_STEPS = 'action-steps';
export const THINKIFIC_SSO = 'thinkific-sso';
export const COMMUNITY_SSO = 'community-sso';
export const COMMUNITY_SPACE_SSO = 'community-space-sso';
export const ACADEMIC_YEARS = 'academicYears';
export const ACADEMIC_YEARS_WITH_PERIODS = 'academicYears';
export const TEACHER_STRATEGIES = 'teacherStrategies';
export const REFERENCE_OPTIONS = 'REFERENCE_OPTIONS';
//Pathways V2
export const PATHWAYS_V2 = 'PATHWAYS_V2';
export const PATHWAYS_V2_STEP = 'PATHWAYS_V2_STEP';
export const SHARED_RESOURCES = 'SHARED_RESOURCES';
export const FOCUS_OPTIONS = 'FOCUS_OPTIONS';
export const PLANS = 'plans';
export const STEPS = 'steps';
//Pathways V3
export const PATHWAYS_V3 = 'PATHWAYS_V3';
export const V3_TEMPLATE_STRATEGIES = 'V3_TEMPLATE_STRATEGIES';
export const V3_TEMPLATE_GOALS = 'V3_TEMPLATE_GOALS';
export const V3_PATHWAY_ACTION_TAGS = 'V3_PATHWAY_ACTION_TAGS';
export const V3_SHARED_RESOURCES = 'V3_SHARED_RESOURCES';
//Passport
export const PASSPORT_ELEMENTS = 'PASSPORT_ELEMENTS';
// Users
export const MENTORS = 'MENTORS';
export const USER_MANAGEMENT_LIST = 'user-management-list';
export const USERS_MONITORING_LIST = 'USERS_MONITORING_LIST';
export const MATS = 'MATS';
export const MATS_SCHOOLS = 'MATS_SCHOOLS';
export const SCHOOL_ASSOCIATED_USER_OPTIONS = 'SCHOOL_ASSOCIATED_USER_OPTIONS';
export const ASSOCIATED_USER_OPTIONS = 'ASSOCIATED_USER_OPTIONS';
export const EARLY_ACCESS_GROUPS_OPTIONS = 'EARLY_ACCESS_GROUPS_OPTIONS';
export const SCHOOL_TEACHERS = 'SCHOOL_TEACHERS';
export const SCHOOL_FROM_COORDINATOR = 'SCHOOL_FROM_COORDINATOR';
// Dashboard
export const DASHBOARD = 'dashboard';
export const NOTIFICATIONS = 'notifications';
// Global notifications
export const GLOBAL_NOTIFICATIONS = 'globalNotifications';
// Events
export const EVENTS = 'EVENTS';
export const SHARED_EVENTS = 'SHARED_EVENTS';
export const CALENDAR_EVENTS = 'CALENDAR_EVENTS';
// HubSpot
export const ADVISOR_INFO = 'advisorInfo';
//Advisor
export const ALL_SCHOOLS = 'schools';
export const SCHOOL_NOTES = 'notes';
export const WEBINARS = 'webinars';
export const WEBINAR = 'webinar';
export const SIGNATURE = 'signature';
export const POSTS = 'posts';
export const POST = 'post';
export const EMBED = 'embed';

//Resource
export const RESOURCE = 'resource';
export const RESOURCES = 'resources';

export const DRAFT_RESOURCES = 'draft_resources';
export const ALGOLIA_RESOURCES = 'resources';
export const RESOURCES_BY_TYPE = 'resourceByType';

export const RESOURCES_BY_SLUG = 'resourceBySlug';

export const NIOT_TRACKING = 'courcseTracking';

export const SCHOOL_METRICS = 'schoolMetrics';

export const FEATURE_FLAG = 'featureFlag';

export const VARIANTS = 'variant';

export const ESE_USERS = 'eseUser';

export const ONBOARDING_TRACKING = 'onboardingTracking';

export const DASHBOARD_CHECKLIST = 'dashboardChecklist';

export const ECT_MEETING = 'ect_meeting';

export const ASSOCIATE_COLLEGES = 'associate_colleges';

export const COHORT = 'cohort';


getVariants;
